import * as React from 'react';
import { useEffect, useState } from 'react';
import EditOrganizationDialog from "./AdminModal";
import { GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { StripedDataGrid } from '../dashboard/dashboardStyles';
import { useStyles } from "./adminStyles";
import { IconButton } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const props: GridRowsProp = [
    { id: 12345, name: 'General LLC', email: 'admin@email.com', added: '11/11/11',
    project: 'Project 1', admin: 'Boss, M', permissions: [
      {id: 1, permission: true, name: 'API Acess'},
      {id: 2, permission: false, name: 'Icon Access'},
      {id: 3, permission: true, name: 'Live View'}
    ] },
    { id: 54321, name: 'Service Company', email: 'admin@email.com', added: '11/11/11',
    project: 'Project 1', admin: 'Boss, M', permissions: [
      {id: 1, permission: true, name: 'API Acess'},
      {id: 2, permission: false, name: 'Permission B'},
      {id: 3, permission: true, name: 'Permission C'}
    ] },
    { id: 77777, name: 'Fleet Brothers', email: 'admin@email.com', added: '10/10/10',
    project: 'Project 2', admin: 'Boss, M', permissions: [
      {id: 1, permission: true, name: 'API Acess'},
      {id: 2, permission: false, name: 'Icon Access'},
      {id: 3, permission: true, name: 'Live View'}
    ] },
  ];
  
  const handleCellClick = (param, event) => {
    event.stopPropagation();
  };
  
  const handleRowClick = (param, event) => {
    event.stopPropagation();
  };

export default function AdminCard() {
    const [organization, setOrganization] = useState([]);
    const [showModal, setModal] = useState<boolean>(false);
    const columns: GridColDef[] = [
      { headerName: 'Organization', field: 'name', flex: 1 },
      { headerName: 'Email', field: 'email', flex: 1 },
      { headerName: 'Date Added', field: 'added', flex: 0.7 },
      { headerName: 'Administrator', field: 'admin', flex: 1 },
      {
      field: ' ',
      flex: 0.2,
      renderCell: (params) => {
          return (
              <>
              <IconButton
              id="basic-button"
              onClick={() => { setModal(true)} }>
              <AdminPanelSettingsIcon />
            </IconButton><EditOrganizationDialog organization={params.row} open={showModal} 
              closeHandler={(value) => { setModal(value); } } />
            </>
          );
        } 
      },
    ];
return (
    <div style={{ height: 'auto', width: "100%" }}>
    <StripedDataGrid rows={props} columns={columns} 
    getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
    hideFooter={true}
    autoHeight={true}
    onCellClick={handleCellClick}
    onRowClick={handleRowClick}
    components={{ Toolbar: GridToolbar }}
    />
    </div>
);
}

