import * as React from 'react';
import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
  IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Select, SelectChangeEvent,
  TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core';
import { Member, Organization, Team } from '../../../../types';
import APIClient from '../../../service/api.service';
import { useTeamMembers } from './hooks/teamMemberfetch';
import { Loading, useModalLoadingState } from '../../shared/Loading';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& .MuiTextField-root': { marginBottom: 10, width: '100%' },
    '& .MuiFormControl-root': { marginBottom: 10, width: '100%' },
    },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  subItem: {
    marginBottom: 10,
    paddingLeft: 20,
  },
}));

type Props = {
  teamData: Team;
  show: boolean;
  toggleModal: Function;
};

export const ViewTeamsModal = (Props) => {
  const classes = useStyles();

  if (!Props.show) {
    return null;
  }

  if (!Array.isArray(Props.teamData.members)) {
    return null;
  }
  
  return (
    <>
    <Modal
      open={Props.show}
      onClose={() => { Props.toggleModal(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <IconButton className={classes.closeButton} onClick={() => Props.toggleModal(false)}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h5" component="h5">
          View Details for Team {Props.teamData.name}
        </Typography>
        <div className={classes.subItem}>
          <Typography variant="h6" component="h6">
            Description: {Props.teamData.description}
          </Typography>
        </div>
        <div className={classes.subItem}>
          <Typography variant="h6" component="h6">
            Address: {Props.teamData.address ?? 'N/A'}
          </Typography>
        </div>
        <div className={classes.subItem}>
          <Typography variant="h6" component="h6">
            Team Owner: {Props.teamData.owner.name}
          </Typography>
        </div>
        <div className={classes.subItem}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {Props.teamData.members.map((member) => (
            <ListItem key={String(member.id)}>
              <ListItemText primary={member.name} secondary={member.email} />
            </ListItem>
          ))}
          </List>
        </div>
      </Box>
    </Modal>
    </>
  )
}

export const EditTeamsModal = (Props) => {
  const [isLoading, setIsLoading] = useModalLoadingState();
  const [error, setError] = useState<string>('');
  const [formValues, setFormValues] = useState<Partial<Organization>>({
    id: Props.teamData.id,
    name: Props.teamData.name,
    description: Props.teamData.description,
    address: Props.teamData.address ?? '',
    team_owner_id: -1,
    parent_organization_id: Props.teamData.parent_organization_id,
    organization_name: '',
    members: Props.teamData.members
  });
  const classes = useStyles();
  const [userList, setUserList] = useState<Member[]>([]);
  const [owner, setOwner] = useState(JSON.stringify(Props.teamData.owner) ?? "");
  const [selectedMembers, setSelectedMembers] = useState<Member[]>(Props.teamData.members ? Props.teamData.members : []);
  useEffect(() => {
    setFormValues(Props.teamData);
    setOwner(JSON.stringify(Props.teamData.owner) ?? "");
  }, [Props.teamData]);

  useEffect(() => {
    if (Props.teamData.parent_organization_id) {
      const apiClient = new APIClient();
      apiClient.getAllUsers(formValues.parent_organization_id).then((response) => {
        const { directory } = response[0];
        setUserList(directory);
      });
    }
  }, [Props.teamData.id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name!]: value,
    }));
  };

  const handleOwnerChange = (e) => {
    setOwner(e.target.value);
  };

  const onSubmit = () => {
    const selectedMemberIds = selectedMembers.map((member) => member.id);
  const updatedFormValues = {
    ...formValues,
    team_owner_id: JSON.parse(owner).id,
    members: selectedMemberIds,
  };
    setIsLoading(true);
    let apiClient = new APIClient();
    apiClient.updateTeam(updatedFormValues)
    .then((response)=> {
      //remove the old team data and replace with new team data
      Props.setTeams([response, ...Props.teams.filter((team) => team.id != response.id)]);
      setIsLoading(false);
      Props.toggleModal(false);
    })
    .catch((error) => {
      setIsLoading(false);
      alert('An error occurred while updating the team. Please try again.');
    });
  }

  if (!Props.show) {
    return null;
  }

  return (
  <>
  <Modal open={Props.show} onClose={() => { Props.toggleModal(false)}}>
  <Box className={classes.modal}>
    <IconButton className={classes.closeButton} onClick={() => Props.toggleModal(false)}>
      <CloseIcon />
    </IconButton>
    <Loading isOpen={isLoading} error={error} />
      <Typography id="modal-modal-title" variant="h4" component="h4">
        Edit Team {Props.teamData.name}
      </Typography>
    <div>
    <TextField
      required
      id="outlined-required"
      name="name"
      label="Team Name" 
      value={formValues.name ?? ""}
      onChange={handleInputChange}/>
    </div>
    <div>
    <TextField
      id="outlined-basic"
      name="description"
      label="Description"
      value={formValues.description ?? ""}
      onChange={handleInputChange} />
    </div>
    <div>
    <TextField
      id="outlined-select-basic"
      name="address"
      label="Address"
      value={formValues.address ?? ""}
      onChange={handleInputChange} />
    </div>
    <div>
    <TextField
      id="outlined-select-basic"
      select
      name="team_owner_id"
      label="Owner"
      value={owner}
      onChange={handleOwnerChange}
    >
      {userList.map((owner) => (
        <MenuItem key={String(owner.id)} value={JSON.stringify(owner)}>
          {`${owner.name}`}
        </MenuItem>
      ))}
    </TextField>
    </div>
    {/* <div>
    <Autocomplete
    multiple
    id="tags-standard"
    options={userList}
    getOptionLabel={(option) => String(option.name)}
    value={selectedMembers}
    onChange={(event, newValue) => setSelectedMembers(newValue)}
    defaultValue={selectedMembers}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        label="Team Members"
        placeholder="Select Team Members"
      />
    )}
    data-testid="team-members-select"
  />
    </div> */}
  <Button
    onClick={onSubmit}
    variant="contained"
    disabled={isLoading}
    sx={{
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: '16px auto',
    }}>
    {isLoading ? 'Please Wait...' : 'Submit'}
  </Button>
  </Box>
  </Modal>
  </>
  )
}

export const DeleteTeamModal = (Props) => {
  const [isLoading, setIsLoading] = useModalLoadingState();
  const [error, setError] = useState<string>('');
  const style = useStyles();
  const handleSubmit = () => {
    setIsLoading(true);
    let apiClient = new APIClient();
    apiClient.deleteTeam(Props.teamData.id)
    .then((reponse)=> {
      setIsLoading(false);
      //Remove the team from props
      Props.setTeams(Props.teams.filter((team) => team.id != Props.teamData.id));
      Props.toggleModal(false);
    })
    .catch((error) => {
      setIsLoading(false);
      alert('An error occurred while deleting the team. Please try again.');
    });
  };

  if (!Props.show) {
    return null;
  }

  return (
    <Dialog open={Props.show} onClose={() => { Props.toggleModal(false)}}>
      <Loading isOpen={isLoading} error={error} />
        <DialogTitle>
          {'Are you sure you want to delete '+Props.teamData.name+'?'}
        </DialogTitle>
        <DialogActions>
          <Button
          onClick={handleSubmit}
          type='submit'>
            {'Yes'}
          </Button>
          <Button onClick={() => { Props.toggleModal(false)}}>
            {'No'}
          </Button>
        </DialogActions>
    </Dialog>
  );
}