import React, { useState } from "react";
import {
  Box, Button, IconButton, Modal, TextField, Typography, MenuItem
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import BusinessIcon from '@mui/icons-material/Business';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Stack from '@mui/material/Stack';
import TeamsTableProvider from "../../Teams/providers/TeamsTableProvider";
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2, 4, 3),
    "& .MuiTextField-root": { marginBottom: 10, width: "100%" },
    "& .MuiFormControl-root": { marginBottom: 10, width: "100%" },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default function UserModal(Props) {
  const { show, setShowModal, user, submitUserUpdate, deleteUserFromOrganization, deleteUserFromTeam, addUserToTeam } = Props;
  const [formValues, setFormValues] = useState(cloneDeep(user));
  const [portalOrgs, setPortalOrgs] = useState(cloneDeep(user.portals));
  const [selectTeam, setSelectTeam] = useState(false);
  const classes = useStyles();

  //these should eventually find there way into a general lib of functions.
  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name!]: value,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const { name } = e.target;
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setFormValues({
      ...formValues,
      [name]: formattedPhoneNumber,
    });
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  }

  function addUserToTeamCallback(id) {
    addUserToTeam(id);
    setSelectTeam(false);
  }

  function handlePortalRoleChange(e, organizationId) {
    let portalObjIdx = portalOrgs.findIndex(function (portal) {
      return portal.id == organizationId;
    });
    if (portalObjIdx === -1) {
      //do nothing, but we will literally never hit this case
      throw 500;
    } else {
      const newState = [...portalOrgs];
      newState[portalObjIdx].role_id = e.target.value;
      setPortalOrgs(newState);
    }
  }

  return (
    <>
      <Modal
        open={show}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ borderRadius: '8px' }}
      >
        <Box className={classes.modal}>
          {!selectTeam ?
            <div>
              <div>
                <Stack spacing={2} direction="row" justifyContent="left" sx={{ paddingBottom: '15px' }}>
                  <IconButton sx={{ padding: 0, margin: 0 }}>
                    <Avatar alt={user.name} sx={{ width: 72, height: 72 }} />
                  </IconButton>
                  <TextField
                    disabled={!Props.canEditUserInfo}
                    value={formValues['name']}
                    required
                    id="outlined-required"
                    name="name"
                    label="Name"
                    onChange={handleNameChange} />
                </Stack>

              </div>
              <div>
                <TextField
                  disabled
                  value={user.email}
                  id="outlined-basic"
                  label="Email" />
              </div>
              <div>
                <TextField
                  disabled={!Props.canEditUserInfo}
                  id="outlined-basic"
                  name="phone_number"
                  label="Phone Number"
                  variant="outlined"
                  value={formValues['phone_number'] ?? ""}
                  onChange={handlePhoneNumberChange} />
              </div>

              {/* Teams */}
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Teams
                  <IconButton edge="end" aria-label="add" onClick={() => setSelectTeam(true)}>
                    <AddBoxIcon color="success" />
                  </IconButton>
                </Typography>
                <List dense={false}>
                  {user.teams.map((team) => (
                    <ListItem
                      key={team.id}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteUserFromTeam(team)}>
                          <DeleteIcon color="warning" />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <BusinessIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={team.name}
                        secondary={team.role_name}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              {/* Organizations */}
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Organizations
                  {/* <IconButton edge="end" aria-label="add">
                    <AddBoxIcon color="success" /> //TO-DO add user to org
                  </IconButton> */}
                </Typography>
                <List dense={false}>
                  {portalOrgs.map((organization) => (
                    <ListItem
                      key={organization.id}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteUserFromOrganization(organization)}>
                          <DeleteIcon color="warning" />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <BusinessIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={organization.name}
                        secondary={organization.roleOptions ? <TextField
                          id="outlined-select-basic"
                          select
                          name="organization_role_id"
                          label="Role"
                          size="small"
                          value={organization.role_id}
                          onChange={(e) => { handlePortalRoleChange(e, organization.id) }}
                        >
                          {organization.roleOptions.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                              {`${role.display_name}`}
                            </MenuItem>
                          ))}
                        </TextField> :
                        organization.role_name}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  onClick={() => setShowModal(false)}
                  variant="contained"
                  sx={{ background: "grey" }}>
                  Close
                </Button>
                <Button
                  onClick={() => submitUserUpdate(formValues, portalOrgs)}
                  variant="contained"
                  color="success">
                  Save
                </Button>
              </Stack>
            </div>
            :
            //display team selection
            <div>
              <TeamsTableProvider selectTeamCallback={addUserToTeamCallback} />
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  onClick={() => setSelectTeam(false)}
                  variant="contained"
                  sx={{ background: "grey" }}>
                  Back
                </Button>
              </Stack>
            </div>
          }
        </Box>
      </Modal>
    </>
  );
}