import * as React from 'react';
import {Button, Box, Grid } from '@mui/material';
// @ts-ignore
import ICON from '../../../images/TestAppImages/ICON-Logo.webp';
import { AppLogo, Item } from '../dashboardStyles';

export default function Apps() {
    return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5} justifyContent="space-around">
            <Grid item xs="auto">
              <Item>
              <Button href="https://fleet.tdpdrive.com/">
                <AppLogo src={ICON} />
              </Button>
              </Item>
            </Grid>
          </Grid>
        </Box>
      );
}