import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';

const API_URL = window.location.protocol + "//" + window.location.host.replace("mq", "api");
const CODE = window.location.search;

export const Login = () => {
    window.location.href = `${API_URL}/accounts/v1/auth/social/fusionauth/redirect?to=front-end&sub=mq`;
    return (<></>);
};

export function listenCookieDeleted(callback, interval = 1000) {
  let lastCookie = Cookies.get('credentials');
  let fusionId = Cookies.get('fusionId');
  setInterval(()=> {
    let cookie = Cookies.get('credentials');
    if (lastCookie && !cookie) {
      try {
        callback({oldValue: lastCookie, newValue: cookie, fusionId: fusionId});
      } finally {
        lastCookie = cookie;
      }
    }
  }, interval);
}

const Social = () => {
    const [creds, setCreds] = useState({ stored: !!Cookies.get('credentials'), loading: false });

    useEffect(() => {
        if (!creds.stored && !creds.loading && CODE) {
            const handshakeUrl = `/accounts/v1/auth/social/fusionauth/handshake/front-end${CODE}&sub=mq`;
            axios.post(API_URL + handshakeUrl)
                .then(res => {
                    let data = res.data;
                    //normalize the expiration
                    let expiry = new Date();
                    expiry.setSeconds(expiry.getSeconds() + data.expires_in);
                    data.expires_in = expiry;
                    Cookies.set('credentials', data.access_token, {expires: data.expires_in});
                    Cookies.set('user', JSON.stringify(data.user), {expires: data.expires_in});
                    Cookies.set('fusionId', data.userId, {expires: (data.expires_in)});
                    //because there is no api for getting a cookie expiration date
                    Cookies.set('cookieExpiration', data.expires_in, {expires: (data.expires_in)});
                    Cookies.set('fusionTokenExpiry', (new Date(data.expires_in) - new Date().getTime()), {expires: (data.expires_in)});
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => setCreds({ stored: true, loading: false }));
        }
    }, [creds]);
    return !creds.stored ? (<>{CODE ? ("Loading ...") : ("Handshake failed. Missing code.")}</>) : (
        <Navigate to={"/"} />);
};
export default Social;
