import * as React from 'react';
import { Box, IconButton, Menu, MenuItem, StyledEngineProvider, Toolbar,
  Typography, useTheme } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ThemeProvider as SCThemeProvider } from "styled-components";
// @ts-ignore
import MQLogo from '../../images/mq-logo.webp';
import { Logo, Header, Content } from './mainStyles';
import APIClient from '../../service/api.service';
import Cookies from 'js-cookie';

export default function Main(props) {
  const muiTheme = useTheme();
  const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAuth(event.target.checked);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = async () => {
      let apiClient = new APIClient();
      await apiClient.socialLogout();
      Cookies.remove('credentials', { path: ''});
      Cookies.remove('user', { path: ''});
      window.location.reload();
    }
  return (
    <StyledEngineProvider injectFirst>
      <SCThemeProvider theme={muiTheme}>
      <Header position="static">
        <Toolbar disableGutters>
            <Logo src={MQLogo} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            </Typography>
            {auth && (
            <Box >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{ ml: 2 }}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
    </Header>

        <Content>
          {props.children}
        </Content>
      </SCThemeProvider>
    </StyledEngineProvider>
  );
}
