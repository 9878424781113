import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Logo = styled.img`
  width:  50px;
`

export const Header = styled(AppBar)`
  padding: 8px 15px 8px;
  margin-bottom: 15px;
  background: linear-gradient(#545454, #444444);
  border-top: 3px solid #EF4035;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  overflow: hidden
  align-items: center;

  h1 {
    color: white;
    font-size: 1.5em;
    display: inline-block;
    margin-left: 15px;
  }
`

export const Content = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
`