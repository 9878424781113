import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

export default function useConfirm(props) {
    const { title = "Confirmation", message = "Please Confirm", confirmText = "OK", declineText = "Cancel" } = props;
    const [decision, setDecision] = useState(null);
    const openConfirm = () => new Promise((resolve, reject) => setDecision({ resolve }));
    const handleClose = () => setDecision(null);
    const onConfirm = () => {
        decision?.resolve(true);
        handleClose();
    };
    const onDecline = () => {
        decision?.resolve(false);
        handleClose();
    };

    // Create the dialog
    const ConfirmDialog = () => (
        <Dialog open={decision !== null}>
            <DialogTitle style={{ textAlign: "center" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <span style={{ marginRight: "10px" }}>{title}</span>
                </div>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    id="decline-dialog-button"
                    color="error"
                    variant="contained"
                    onClick={onDecline}>
                    {declineText}
                </Button>
                <Button
                    id="confirm-dialog-button"
                    autoFocus
                    color="primary"
                    variant="contained"
                    onClick={onConfirm}>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return { ConfirmDialog, openConfirm };
};
