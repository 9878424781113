import * as React from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';

export function TeamNotification() {
  return(
  <>
  <ListItemIcon>
    <NotificationsIcon fontSize="small" />
  </ListItemIcon>
  <ListItemText>Notification Settings</ListItemText>
  </>
  )
}

export function TeamView() {
  return(
  <>
  <ListItemIcon>
    <VisibilityIcon fontSize="small" />
  </ListItemIcon>
  <ListItemText>View Details</ListItemText>
  </>
  )
}

export function TeamEdit() {
  return(
  <>
  <ListItemIcon>
    <EditIcon fontSize="small" />
  </ListItemIcon><ListItemText>Edit Team Info</ListItemText>
  </>
  )
}

export function TeamMembers() {
  return(
  <>
  <ListItemIcon>
    <GroupIcon fontSize="small" />
  </ListItemIcon>
  <ListItemText>Edit Members</ListItemText>
  </>
  )
}

export function TeamDelete() {
  return(
  <>
  <ListItemIcon>
    <DeleteIcon fontSize="small" />
  </ListItemIcon>
  <ListItemText>Delete Team</ListItemText>
  </>
  )
}