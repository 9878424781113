import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import TeamsTable from "../views/TeamsTable";
import APIClient from "../../../service/api.service";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function TeamsTableProvider(Props) {
  const client = new APIClient();
  const queryClient = useQueryClient();

  const [page, setPage] = React.useState(0);

  const { status, data, error, isFetching, isPreviousData } = useQuery({
    queryKey: ['teams', page],
    queryFn: () => client.getAllTeams(page),
    keepPreviousData: true,
    staleTime: 5000,
  });

  React.useEffect(() => {
    if (!isPreviousData && data?.hasMore) {
      queryClient.prefetchQuery({
        queryKey: ['teams', page + 1],
        queryFn: () => client.getAllTeams(page + 1),
      })
    }
  }, [data, isPreviousData, page, queryClient])

  function selectTeamCallback(id) {
    //To:Do any default funcationality we want here.
  }

  return (
    <>
      {(!isFetching) ?
        <TeamsTable loading={isFetching} page={page} setPage={setPage} selectTeamCallback={Props.selectTeamCallback ? Props.selectTeamCallback : selectTeamCallback} totalRows={data.total} teams={data.data} />
        :
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}
    </>
  );
}