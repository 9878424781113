import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./components/dashboard/Dashboard";
import Main from "./components/layout/Main";
import Social, { Login, listenCookieDeleted } from "./service/social";
import Register from "./service/register";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import Cookies from 'js-cookie';
import APIClient from "./service/api.service";

function App() {
    const queryClient = new QueryClient();
    listenCookieDeleted(async ({oldValue, newValue, fusionId})=> {
      let apiClient = new APIClient();
      await apiClient.socialLogout(fusionId);
      window.location.reload();
    }, 1000);

    return (
      <QueryClientProvider client={queryClient}>
        <Main>
            <Router>
                <Routes>
                    <Route path="/"
                           element={(Cookies.get('credentials') ?? false) ?
                               (<Dashboard />) : (<Login />)
                           } />
                    <Route path="/social" element={<Social />} />
                    <Route path="/register/:token" element={<Register />} />
                </Routes>
            </Router>
        </Main>
      </QueryClientProvider>
    );
}

export default App;
