import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Chip, FormControl, InputLabel, IconButton, MenuItem, Modal, OutlinedInput, Select,
  TextField, Typography, Autocomplete } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core';
import APIClient from '../../../service/api.service';
import { Member, Organization } from '../../../../types';
import { useUsers } from '../users/hooks/userFetch';
import { useTeams } from './hooks/teamFetch';
import { Loading, useModalLoadingState } from '../../shared/Loading';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 550,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& .MuiTextField-root': { marginBottom: 10, width: '100%' },
    '& .MuiFormControl-root': { marginBottom: 10, width: '100%' },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const defaultValues = {
    id: -1,
    name: "",
    description: "",
    address: "",
    country: "",
    team_owner_id: -1,
    type: "team",
    organization_name: "",
    members: [],
    assignable_roles: [],
  }; 

  interface refreshProps {
    refresh: Function
  };

  interface handleProps {
    handleCloseRefresh: Function
  };

export default function CreateTeamModal(Props) {
  const [isLoading, setIsLoading] = useModalLoadingState();
  const [formValues, setFormValues] = useState<Partial<Organization>>({
    members: [],
  });
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [userList, setUserList] = useState<Member[]>([]);
  const [teamOptions, setTeamOptions] = useState<Organization[]>([]);
  const [isOrganizationSelected, setIsOrganizationSelected] = useState(false);
  const [error, setError] = useState<string>('');
  const style = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormValues(defaultValues);
    setOpen(false);
  }

  useEffect(() => {
    let apiClient = new APIClient();
    apiClient.getUserOptions().then((response) => {
      const { organizations, roles } = response;
      setTeamOptions(organizations ?? []);
      });
  }, []);
  useEffect(() => {
    if (formValues.parent_organization_id) {
      const apiClient = new APIClient();
      apiClient.getAllUsers(formValues.parent_organization_id).then((response) => {
        const { directory } = response;
        setUserList(directory);
      });
    }
  }, [formValues.parent_organization_id]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    if (name === 'organization') {
      const selectedOrg = teamOptions.find(org => org.id === value);
      setFormValues(prevValues => ({
        ...prevValues,
        parent_organization_id: value as number,
        organization_name: selectedOrg ? selectedOrg.name : '',
      }));
      setIsOrganizationSelected(true);
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [name!]: value,
      }));
    }
  };

  const onSubmit = () => {
    const selectedMemberIds = selectedMembers.map((member) => member.id);
  const updatedFormValues = {
    ...formValues,
    members: selectedMemberIds,
  };
    setIsLoading(true);
    let apiClient = new APIClient();
    apiClient.createTeam(updatedFormValues)
    .then((response)=> {
      setIsLoading(false);
      //Add team to existing props
      Props.setTeams([...Props.teams, response]);
      handleClose();
    })
    .catch((error) => {
      setIsLoading(false);
      alert('An error occurred while creating the team. Please try again.');
    });
  }
  return (
    <>
    <Button
      variant="contained"
      onClick={handleOpen}
    >Create New Team</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
    <Box className={style.modal}>
    <IconButton className={style.closeButton} onClick={handleClose}>
      <CloseIcon />
    </IconButton>
    <Loading isOpen={isLoading} error={error} />
    <Typography id="modal-modal-title" variant="h4" component="h4">
    Create a New Team
    </Typography>
    <div>
    <TextField
      required
      id="outlined-required"
      name="name"
      label="Team Name" 
      value={formValues.name}
      onChange={handleInputChange}/>
    </div>
    <div>
    <TextField
      id="outlined-basic"
      name="description"
      label="Description"
      value={formValues.description}
      onChange={handleInputChange} />
    </div>
    <div>
    <TextField
      id="outlined-select-basic"
      name="address"
      label="Address"
      value={formValues.address}
      onChange={handleInputChange} />
    </div>
    <div>
    <TextField
      required
      id="outlined-select-basic"
      select
      name="organization"
      label="Organization"
      variant="outlined"
      value={formValues.parent_organization_id}
      onChange={handleInputChange}
      >
      {teamOptions.map((org) => (
      <MenuItem key={org.id} value={org.id}>
        {org.name}
      </MenuItem>
    ))}
    </TextField>  
    </div>
    <div>
    <TextField
      disabled={!isOrganizationSelected} 
      id="outlined-select-basic"
      select
      name="team_owner_id"
      label="Owner"
      value={formValues.team_owner_id}
      onChange={handleInputChange}
    >
      {userList.map((owner) => (
        <MenuItem key={String(owner.id)} value={Number(owner.id)}>
          {owner.name}
        </MenuItem>
      ))}
    </TextField>
    </div>
    <div>
    <FormControl>
    <Autocomplete
        disabled={!isOrganizationSelected} 
        multiple
        id="tags-standard"
        options={userList}
        getOptionLabel={(option) => String(option.name)}
        value={selectedMembers}
        onChange={(event, newValue) => setSelectedMembers(newValue)}
        defaultValue={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Team Members"
            placeholder="Select Team Members"
          />
        )}
        data-testid="team-members-select"
      />
    </FormControl>
    </div>
    <Button
    onClick={onSubmit}
    variant="contained"
    disabled={isLoading}
    sx={{
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      margin: '16px auto',
    }}>
      {isLoading ? 'Please Wait...' : 'Submit'}
    </Button>
    </Box>
    </Modal>
    </>
  );
}