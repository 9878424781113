import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material'
import Notifications from './notifications/Notifications';
import Teams from './teams/Teams';
import Users from './users/Users';
import Apps from './apps/Apps';
import AdminCard from '../admin/Admin';

export default function Dashboard() {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="Header">
        <div className="Title">
          Your Dashboard
        </div>
      </div>

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="Apps" />
          <Tab label="Teams" />
          <Tab label="Notifications" />
          <Tab label="Users" />
          <Tab label="Admin" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {value === 0 && (
          <Box>
            <Apps />
          </Box>
        )}
        {value === 1 && (
          <Box>
            <Teams />
          </Box>
        )}
        {value === 2 && (
          <Box>
            <Notifications />
          </Box>
        )}
        {value === 3 && (
          <Box>
            <Users />
          </Box>
        )}
        {value === 4 && (
          <Box>
            <AdminCard />
          </Box>
        )}
      </Box>

      
    </div>
  );
}
