import React from 'react';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress } from '@mui/material';

export function Loading(props) {
    const { isOpen, message } = props;

  return (
    <Dialog open={isOpen}>
      <DialogTitle style={{ textAlign: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ marginRight: '10px' }}>Loading</span>
          <CircularProgress color="primary" size={20} />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        {message ? message : 'Please wait...'}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export const useGridLoadingState = () => {
  const [loading, setLoading] = useState(true);
  return [loading, setLoading] as const;
};

export const useModalLoadingState = () => {
  const [isLoading, setIsLoading] = useState(false);
  return [isLoading, setIsLoading] as const;
};
