import * as React from 'react';
import {useEffect, MouseEvent, useState } from 'react';
import {
  GridColDef, GridRowsProp, GridToolbar, GridActionsCellItem, GridEnrichedColDef,
  GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { StripedDataGrid } from '../dashboardStyles';
import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { TeamNotification, TeamEdit, TeamDelete, TeamView } from './TeamMenu';
import { DeleteTeamModal, EditTeamsModal, ViewTeamsModal } from './TeamsModal';
import CreateTeamModal from './CreateTeamModal';
import { Team } from '../../../../types';
import { useGridLoadingState } from '../../shared/Loading';
import APIClient from "../../../service/api.service";

const handleCellClick = (param, event) => {
  event.stopPropagation();
};

const handleRowClick = (param, event) => {
  event.stopPropagation();
};

const defaultValues: Team = {
  id: -1,
  name: "",
  description: "",
  address: "",
  owner: "",
  members: 0,
};

function GridToolbarMyTeams() {
  return (
    <Box marginLeft="auto">
      <FormControlLabel control={<Checkbox defaultChecked />} label="Show Only My Teams" style={{ marginRight: 0 }} />
      <SupervisedUserCircleIcon style={{ fill: 'green', verticalAlign: 'middle', paddingBottom: '0.1em' }} />
    </Box>
  )
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {/* <GridToolbarMyTeams /> */}
    </GridToolbarContainer>
  )
}

export default function Teams() {
  const currentUser = '';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<Team>(defaultValues);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loading, setLoading] = useGridLoadingState();
  const open = Boolean(anchorEl);
  const moreOptions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };

  const [teams, setTeams] = useState<Team[]>([]);
  const [teamsState, setTeamsState] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(1);

  useEffect(() => {
    let apiClient = new APIClient();
    setLoading(true);
    apiClient.getAllTeams(currentPage + 1).then((response) => {
      setTeams(response.data);
      setTotalRows(response.total);
      setLoading(false);
    });
  }, [currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page);
  };

  const handleClose = (team) => {
    setTeamsState(!teamsState);
  };

  const handleOpen = (params, type) => {
    let apiClient = new APIClient();
    apiClient.getTeamMembers(params.id).then((response) => {
      setSelectedItem({
        id: response.id,
        name: response.name,
        owner: response.owner,
        parent_organization_id: response.parent_organization_id,
        address: response.address,
        description: response.description,
        members: response.members
      });
    });
    switch (type) {
      case 'view':
        return setShowViewModal(true);

      case 'edit':
        return setShowEditModal(true);

      case 'delete':
        return setShowDeleteModal(true);
    }
  };

  function getOwnerName(params) {
    return params.row.owner?.name ?? null;
  }

  function getMembersCount(params) {
    return params.row.members?.length;
  }

  const columns: GridEnrichedColDef[] = [
    {
      headerName: 'Name', field: 'name', flex: 0.7,
      renderCell: (params) => {
        if (currentUser === params.row.owner?.name) {
          return (
            <>
              {params.value}
              <Tooltip id='team-label' title='You are Team Owner'>
                <SupervisedUserCircleIcon style={{ fill: 'green' }} />
              </Tooltip>
            </>
          )
        }
      }
    },
    { headerName: 'Owner', field: 'team_owner', flex: 0.5, valueGetter: getOwnerName },
    { headerName: 'Address', field: 'address', flex: 0.4 },
    { headerName: 'Description', field: 'description', flex: 1 },
    { headerName: 'Members', field: 'member_count', flex: 0.3, valueGetter: getMembersCount },
    {
      field: '',
      type: 'actions',
      flex: 0.2,
      getActions: (params) => [
        // <GridActionsCellItem
        //   icon={<TeamNotification />}
        //   label=""
        //   onClick={() => {}}
        //   showInMenu
        // />,
        <GridActionsCellItem
          icon={<TeamView />}
          label=""
          onClick={() => handleOpen(params.row, 'view')}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<TeamEdit />}
          label=""
          onClick={() => handleOpen(params.row, 'edit')}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<TeamDelete />}
          label=""
          onClick={() => handleOpen(params.row, 'delete')}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <CreateTeamModal 
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          margin: '1rem',
        }}
        teams={teams}
        setTeams={setTeams} />
      <div style={{ height: 'auto', width: "100%" }}>
        <StripedDataGrid rows={teams} columns={columns}
          getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
          autoHeight={true}
          pagination
          pageSize={15}
          rowCount={totalRows}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          page={currentPage}
          onCellClick={handleCellClick}
          onRowClick={handleRowClick}
          loading={loading}
          components={{ Toolbar: GridToolbar }} />
      </div>
      <ViewTeamsModal teamData={selectedItem!} show={showViewModal} 
      toggleModal={(value) => { setShowViewModal(value); }} />
      <EditTeamsModal teams={teams} setTeams={setTeams} teamData={selectedItem!} 
      show={showEditModal} toggleModal={(value) => { setShowEditModal(value); }} />
      <DeleteTeamModal teamData={selectedItem!} show={showDeleteModal} teams={teams} 
      setTeams={setTeams} toggleModal={(value) => { setShowDeleteModal(value); }} />
    </div>
  );
}