import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Box, MenuItem, Modal, TextField, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import APIClient from "../../../service/api.service";
import { makeStyles } from "@material-ui/core";
import { Member } from "../../../../types";
import { Loading, useModalLoadingState } from "../../shared/Loading";
import useAlert from "../../../hooks/useDialog";
import useConfirm from "../../../hooks/useConfirm";

export const SUCCESS_MESSAGE = "Invitation created.";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        "& .MuiTextField-root": { marginBottom: 10, width: "100%" },
        "& .MuiFormControl-root": { marginBottom: 10, width: "100%" },
    },
    closeButton: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

type Organization = {
    id: number;
    parent_organization_id: number | null;
    name: string;
    address: string;
    country: string;
    description: string;
    type: string;
    members: Member[];
    assignable_roles: string[];
};

type Role = {
    id: number;
    name: string;
    display_name: string;
};

export default function CreateUserModal(ModalProps) {
    const defaultValues = {
        name: "",
        email: "",
        phone_number: "",
        organization_id: "" as unknown,
        organization_name: "",
        roles: [] as number[],
    };
    const [isLoading, setIsLoading] = useModalLoadingState();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(defaultValues);
    const [userOptions, setUserOptions] = useState<Organization[]>([]);
    const [isOrganizationSelected, setIsOrganizationSelected] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const style = useStyles();
    const { AlertDialog, openAlert } = useAlert({ title: "An error occurred while creating the user invitation." });
    const { ConfirmDialog, openConfirm } = useConfirm({
        title: "Success!",
        message: "Invitation created. Would you like to invite another user?",
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        let apiClient = new APIClient();
        apiClient.getUserOptions().then((response) => {
            const { organizations } = response;
            const roles = (organizations[0].roles ?? []);
            setRoles(roles);
            setUserOptions(organizations);
        });
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {
        name?: string;
        value: unknown
    }>) => {
        const { name, value } = e.target;
        if (name === "organization") {
            const selectedOrg = userOptions.find(org => org.id === value);
            setFormValues(prevValues => ({
                ...prevValues,
                organization_id: value as number,
                organization_name: selectedOrg ? selectedOrg.name : "",
            }));
            setIsOrganizationSelected(true);
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [name!]: value,
            }));
        }
    };

    const handlePhoneNumberChange = (e) => {
        const { name } = e.target;
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setFormValues({
            ...formValues,
            [name]: formattedPhoneNumber,
        });
    };

    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, "");
        if (phoneNumber.length < 4) return phoneNumber;
        if (phoneNumber.length < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSelectedRoles(value as unknown as number[]);
    };

    const onSubmit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formValues.email as string)) {
            openAlert({ content: "Please enter a valid email address" });
            return;
        }
        formValues.roles = selectedRoles;
        const updatedFormValues = {
            ...formValues,
            email: formValues.email as string,
        };
        setIsLoading(true);
        let apiClient = new APIClient();
        apiClient.userInvite(updatedFormValues)
            .then((response) => {
                if (response.results?.[formValues.email] !== SUCCESS_MESSAGE) {
                    openAlert({
                        content: (<>
                            <p>{`The user with email ${formValues.email} already has access to ${formValues.organization_name}, or has a pending invitation.`}</p>
                            <p>{`To update the user, please find them in the users list and choose [Edit User Info] from the action menu on the right.`}</p>
                        </>),
                    });
                } else {
                    setFormValues(defaultValues);
                    openConfirm().then((decision) => decision ? "" : handleClose());
                }
            })
            .catch((error) => {
                let alertText = (<>"Please try again."</>);
                if (error?.response?.status === 422) {
                    let errorList = Object.values(error.response.data?.errors as [string[]] ?? [])
                        .map((field, f) =>
                            (<div key={`field_${f}`}>
                                {field.map((err, e) =>
                                    (<li key={`err_${e}`}>{err}</li>)
                                )}
                            </div>)
                        );

                    alertText = (<div key={"res_err_li"}>{errorList}</div>);
                }

                openAlert({ content: alertText });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Create New User</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    component="form"
                    className={style.paper}
                    noValidate
                    onSubmit={onSubmit}
                    autoComplete="off"
                >
                    <IconButton className={style.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Loading isOpen={isLoading} />
                    <AlertDialog />
                    <ConfirmDialog />
                    <Typography id="modal-modal-title" variant="h4" component="h4">
                        Create a New User
                    </Typography>
                    <div>
                        <TextField
                            required
                            id="outlined-basic"
                            name="email"
                            label="Email"
                            value={formValues.email}
                            onChange={handleInputChange} />
                        <TextField
                            id="outlined-required"
                            name="name"
                            label="Name"
                            value={formValues.name}
                            onChange={handleInputChange} />
                    </div>
                    <div>
                        <TextField
                            id="outlined-basic"
                            name="phone_number"
                            label="Phone Number"
                            variant="outlined"
                            value={formValues.phone_number}
                            onChange={handlePhoneNumberChange} />
                    </div>
                    <div>
                        <TextField
                            required
                            id="outlined-select-basic"
                            select
                            name="organization"
                            label="Organization"
                            value={formValues.organization_id ?? ""}
                            onChange={handleInputChange}
                            data-testid="organization-select"
                        >
                            {userOptions.map((org) => (
                                <MenuItem key={org.id} value={org.id}>
                                    {org.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            disabled={!isOrganizationSelected}
                            select
                            label="Roles"
                            value={selectedRoles ?? []}
                            onChange={handleRoleChange}
                            variant="outlined"
                            SelectProps={{ multiple: true }}
                        >
                            {roles.map((role) => (
                                <MenuItem key={role.id} value={role.id}>
                                    {role.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <Button onClick={onSubmit} variant="contained" color="primary" type="button">
                        Confirm and Send Invite
                    </Button>
                </Box>
            </Modal>
        </>
    );
}
