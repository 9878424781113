/* eslint-disable no-restricted-globals */
import axios from "axios";
import Cookies from 'js-cookie';

const BASE_URI = location.protocol + "//" + location.host.replace("mq", "api");

const client = axios.create({
    baseURL: BASE_URI,
    json: true,
});
const ACCAPI = "/accounts/v1/mq";

////////////////////////////////////////////////
////             User Auth                  ////
////////////////////////////////////////////////

client.interceptors.request.use(
  async (request) => {
    let cookieExpiration = new Date(Cookies.get('cookieExpiration'));
    let currentTime = new Date().getTime();

    //if there is only 10 minutes left refresh the token
    if(!Cookies.get('requestedNewToken') && (request.url != `${ACCAPI}/fusion/token/refresh`) && (Math.abs(cookieExpiration - currentTime) < 600000)) {
      //Guard to prevent calling this multiple times (10 seconds)
      let guardExpires = new Date (new Date().getTime() + 10 * 1000);
      Cookies.set('requestedNewToken', true, {expires: guardExpires});
      let apiClient = new APIClient();
      let newToken = await apiClient.refreshToken();
      let tokenExpires = new Date(new Date().getTime() + Number(Cookies.get('fusionTokenExpiry')));
      if(newToken.token) {
        Cookies.set('credentials', newToken.token, {expires: tokenExpires});
      }
    }

    return request;
  }
);

export default class APIClient {
    ////////////////////////////////////////////////
    ////             Requests                   ////
    ////////////////////////////////////////////////

    socialLogin = (url) => {
        return this.perform("get", url);
    };

    refreshToken = () => {
      return this.perform("get", `${ACCAPI}/fusion/token/refresh?fusionId=${Cookies.get('fusionId')}`);
    };

    socialLogout(fusionId = Cookies.get('fusionId')) {
      return this.perform("get", `accounts/v1/auth/social/fusion/logout?userId=${fusionId}`);
    }

    userInvite(data) {
        return this.perform("post", `${ACCAPI}/invite`, data);
    }

    userInviteStatus(token) {
        return this.perform("get", `${ACCAPI}/invite/view/${token}`);
    }

    userAcceptInvite(token, email) {
        return this.perform("post", `${ACCAPI}/invite/accept/${token}`, { "email": email });
    }

    getOrganization() {
        return this.perform("get", `${ACCAPI}/organizations&filter[type]=portal`);
    }

    getAllUsers(org_id) {
        return this.perform("get", `${ACCAPI}/organizations/${org_id ?? ""}?include=directory&filter[type]=portal`);
    }

    getAllTeams(page) {
        return this.perform("get", `${ACCAPI}/teams?page=${page}&include=members,owner`);
    }

    getTeam(id) {
        return this.perform("get", `${ACCAPI}/teams/${id}`, id);
    }

    createTeam(team) {
        return this.perform("post", `${ACCAPI}/organizations/${team.parent_organization_id}/teams`, team);
    }

    updateTeam(data) {
        return this.perform("patch", `${ACCAPI}/teams/${data.id}`, data);
    }

    getTeamMembers(id) {
        return this.perform("get", `${ACCAPI}/teams/${id}?include=members,owner`, id);
    }

    addTeamMembers(id, data) {
        return this.perform("patch", `${ACCAPI}/teams/${id}/add-members`, { members: data });
    }

    removeTeamMembers(id, data) {
        return this.perform("patch", `${ACCAPI}/teams/${id}/remove-members`, data);
    }

    deleteTeam(id) {
        return this.perform("delete", `${ACCAPI}/teams/${id}`);
    }

    getUser(id) {
        return this.perform("get", `${ACCAPI}/users/${id}?include=portals,teams`, id);
    }

    updateUser(data) {
        return this.perform("patch", `${ACCAPI}/users/${data.id}`, data);
    }

    deleteUser(userID, orgID) {
        return this.perform("delete", `${ACCAPI}/users/${userID}/${orgID}?sub_organizations=true`);
    }

    addRole(userID, orgID, roleID) {
      return this.perform("patch", `${ACCAPI}/users/${userID}/roles/${roleID}/${orgID}`);
    }

    removeRole(userID, orgID, roleID) {
      return this.perform("delete", `${ACCAPI}/users/${userID}/roles/${roleID}/${orgID}`);
    }

    getUserOptions() {
        return this.perform("get", `${ACCAPI}/invite/options`);
    }


    ////////////////////////////////////////////////
    ////          Perform HTTP Request          ////
    ////////////////////////////////////////////////
    async perform(method, resource, data, type) {
        let headerValues = {};

        switch (type) {
            case "form":
                break;
            case "admin":
                break;
            case "login":
                break;
            default:
                try {
                    headerValues = {
                        Authorization: `Bearer ${Cookies.get('credentials')}`,
                        Accept: "application/json",
                    };
                } catch (error) {
                    // Handle the error or provide a fallback value
                    headerValues = {
                        Authorization: "Bearer fallback-token",
                    };
                }
                break;
        }

        return client({
            method,
            url: resource,
            data,
            headers: headerValues,
        }).then(function(response) {
            return response.data;
        });

    }

}
