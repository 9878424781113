import React from "react";
import { StripedDataGrid } from '../../../components/dashboard/dashboardStyles';
import { GridEnrichedColDef, GridToolbar } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

export default function TeamsTable(Props) {

  function getOwnerName(params) {
    return params.row.owner?.name ?? null;
  }

  function getMembersCount(params) {
    return params.row.members?.length;
  }

  const columns: GridEnrichedColDef[] = [
    {
      headerName: 'Name', field: 'name', flex: 0.7,
      renderCell: (params) => {
        if ('currentUser' === params.row.owner?.name) { //need to get the current user (merge master for cookie work first)
          return (
            <>
              {params.value}
              <Tooltip id='team-label' title='You are Team Owner'>
                <SupervisedUserCircleIcon style={{ fill: 'green' }} />
              </Tooltip>
            </>
          )
        }
      }
    },
    { headerName: 'Owner', field: 'team_owner', flex: 0.5, valueGetter: getOwnerName },
    { headerName: 'Address', field: 'address', flex: 0.4 },
    { headerName: 'Description', field: 'description', flex: 1 },
    { headerName: 'Members', field: 'member_count', flex: 0.3, valueGetter: getMembersCount }
  ];

  function handleRowClick(event) {
    Props.selectTeamCallback(event.row.id);
  };

  return (
    <div style={{ height: 'auto', width: "100%" }}>
      <StripedDataGrid rows={Props.teams} columns={columns}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
        autoHeight={true}
        pagination
        pageSize={15}
        rowsPerPageOptions={[15]}
        rowCount={Props.totalRows}
        onPageChange={(newPage) => Props.setPage(newPage)}
        page={Props.page}
        onRowClick={(e) => { handleRowClick(e) }}
        loading={Props.loading}
        components={{ Toolbar: GridToolbar }} />
    </div>
  );
}