import React, { useEffect, useState } from "react";
import { GridToolbar, GridEnrichedColDef } from "@mui/x-data-grid";
import { StripedDataGrid } from "../dashboardStyles";
import { Member } from "../../../../types";
import CreateUserModal from "./CreateUserModal";
import { useGridLoadingState } from "../../shared/Loading";
import APIClient from "../../../service/api.service";
import UserModalProvider from "../../../newComponents/Users/providers/UserModalProvider";

export default function Users() {
    const [users, setUsers] = useState<Member[]>([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useGridLoadingState();
    const [initialized, setInitialized] = useState(false);

    const apiClient = new APIClient();
    useEffect(() => {
        if (!users.length && !initialized) {
            setLoading(true);
            //TODO: use a new endpoint specifically to get related users, and with pagination
            apiClient.getAllUsers()
                .then((response) => {
                    const allUsers = {};
                    response.map((org) => org.directory.map((usr) => allUsers[usr.email] = usr));
                    setUsers(Object.values(allUsers));
                    setInitialized(true);
                })
                .catch((e) => { /* what do we want to do here ?*/
                })
                .finally(() => setLoading(false));
        }
    }, []);

    function handleRowClick(event) {
      setSelectedUserId(null);
      setSelectedUserId(event.row.id);
      setShowModal(true);
    };

    const columns: GridEnrichedColDef[] = [
        { headerName: "Name", field: "name", flex: 1 },
        { headerName: "Email", field: "email", flex: 1 },
        { headerName: "Phone", field: "phone_number", flex: 0.7 }
    ];

    return (
        <>
            <div style={{ position: "relative" }}>
                <CreateUserModal
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "1rem",
                    }}
                />
                <div style={{ height: "auto", width: "100%" }}>
                    <StripedDataGrid
                        components={{ Toolbar: GridToolbar }}
                        rows={users}
                        columns={columns}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                        }
                        autoHeight={true}
                        onRowClick={(e) => {
                          handleRowClick(e);
                        }}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 15]}
                        initialState={{
                            pagination: {
                                pageSize: 15,
                            },
                        }}
                    />
                </div>
            </div>
            {showModal ? <UserModalProvider userId={selectedUserId} show={showModal} setShowModal={setShowModal}/> : null}
        </>
    );
}
