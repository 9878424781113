import React, { useState } from "react";
import { useQuery } from "react-query";
import UserModal from "../views/UserModal";
import APIClient from "../../../service/api.service";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Cookies from 'js-cookie';

export default function UserModalProvider(Props) {
  const client = new APIClient();
  const currentUserId = JSON.parse(Cookies.get('user'))?.id ?? null;


  async function aggregateQuery() {
    const roleOptions = (await client.getUserOptions()).organizations ?? null;
    const data = await client.getUser(Props.userId);

    // hate doing post processing here, but no easy way on back end without bogging down all other queries
    data.portals.forEach(function(portal, index) {
      let portalObjIdx = roleOptions.find((organization) => organization.id == portal.id);
      
      if (!portalObjIdx) {
        //user cannot do anything here
        data.portals[index].roleOptions = false;
      } else { 
       data.portals[index].roleOptions = portalObjIdx.roles;
      }
    });
    return data;
  }

  const { isLoading: userIsLoading, status: userStatus, data: userData, error: userError, isFetching: userIsFetching, refetch: refetch } = useQuery(
    "user",
    async () => {
      const aggregateResult = await aggregateQuery();
      return aggregateResult;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  async function submitUserUpdateCallBack(data, modifiedOrgs) {
    //only the users themselves can update themselves
    if((currentUserId == Props.userId)) {
      await client.updateUser(data)
      .catch((e) => {
        alert("An error occurred while updating the user. Please try again.");
      });
    }
    
    await userData.portals.forEach(portal => {
      const found = modifiedOrgs.find((modifiedOrg) => modifiedOrg.id == portal.id);
      if(found && (found.role_id !== portal.role_id)) {
        //update the role
        client.addRole(userData.id, portal.id, found.role_id).catch((e) => {
          alert("An error occurred while adding new user role. Please try again.");
        });
        //delete old role
        client.removeRole(userData.id, portal.id, portal.role_id).catch((e) => {
          alert("An error occurred while removing old user role. Please try again.");
        });
      }
    });

    Props.setShowModal(false);
  }

  function deleteUserFromOrganizationCallback(organization) {
    client.deleteUser(userData.id, organization.id)
    .then(refetch)
    .catch((e) => {
      alert("An error occurred while updating the user. Please try again.");
    });

  }

  function deleteUserFromTeamCallback(team) {
    client.removeTeamMembers(team.id, {members: [userData.id]})
    .then(refetch)
    .catch((e) => {
      alert("An error occurred while removing this user from a team. Please try again.");
    });
  }

  function addUserToTeamCallback(id) {
    client.addTeamMembers(id, [userData.id])
    .then(refetch)
    .catch((e) => {
      alert("An error occurred while removing this user from a team. Please try again.");
    });
    
  }

  return (
    <>
      {(!userIsFetching) ?
        <div>
          <UserModal canEditUserInfo={(currentUserId == Props.userId)} show={Props.show} setShowModal={Props.setShowModal} user={userData}
            submitUserUpdate={submitUserUpdateCallBack}
            deleteUserFromOrganization={deleteUserFromOrganizationCallback}
            deleteUserFromTeam={deleteUserFromTeamCallback} 
            addUserToTeam={addUserToTeamCallback}/>
        </div>
        :
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={Props.show}
        >
          <CircularProgress color="inherit" />
        </Backdrop>}
    </>
  );
}