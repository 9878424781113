import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const useAlert = (defaults = {}) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(defaults.title ?? window.location.host + " says");
    const [content, setContent] = useState(defaults.content ?? "");
    const [button, setButton] = useState(defaults.button ?? <>{"Ok"}</>);

    const openAlert = (props = {}) => {
        setTitle(props.title ?? title);
        setContent(props.content ?? content);
        setButton(props.button ?? button);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const AlertDialog = () => (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {typeof content === "string" ?
                        <DialogContentText id="alert-dialog-description">{content}</DialogContentText> :
                        content
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" type="button" onClick={handleClose}>{button}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

    return { AlertDialog, openAlert };
};

export default useAlert;
