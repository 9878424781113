import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import APIClient from "./api.service";
import Register from "../components/register/Register";
import useAlert from "../hooks/useDialog";
import { useGridLoadingState, Loading } from "../components/shared/Loading";

export const INVITE_MESSAGES = {
    "loading": "Please wait while we load your invitation.",
    "token": "Invalid token.",
    "expired": "Your invitation has expired, please contact an administrator for assistance.",
    "register": "Please verify your email address to accept your invitation and start using the portal.",
    "authenticate": "Please verify your email address to link and start using the portal.",
    "success": "Link successful! Please wait while we redirect you to the portal.",
    "failed_retrieval": "Unable to retrieve invitation, please contact an administrator. Status: ",
    "failed_acceptance": "Unable to complete user setup, please contact an administrator. Status: ",
    "failed": "Please contact an administrator.",
};

export default () => {
    const [loading, setLoading] = useGridLoadingState();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(<>{INVITE_MESSAGES.loading}</>);
    const [invite, setInvite] = useState({ "action": "token" });
    const [email, setEmail] = useState("");
    const apiClient = new APIClient();
    const token = window.location.href.split("/").pop();
    const { AlertDialog, openAlert } = useAlert({ title: "An error occurred" });

    useEffect(() => {
        setLoading(true);
        // call api service with token to get status
        token ?
            apiClient.userInviteStatus(token)
                .then((res) => {
                    setInvite(res);
                    setMessage(<>{INVITE_MESSAGES[res.action]}</>);
                    if (res.action === "expired") {
                        openAlert({ content: INVITE_MESSAGES[res.action] });
                        setError(true);
                    }
                })
                .catch((e) => {
                    setError(true);
                    openAlert({ content: INVITE_MESSAGES.failed_retrieval + e.response?.statusText });
                })
                .finally(() => setLoading(false))
            : (openAlert({ content: INVITE_MESSAGES.token }) && setError(true) && setLoading(false));
    }, []);

    const handleAccept = () => {
        setError(false);
        setLoading(true);
        // call api service with token and email to match
        apiClient.userAcceptInvite(token, email)
            .then((res) => {
                //{success:bool, errors:obj or bool {status:int, message:string}}
                if (res.success) {
                    setMessage(<>INVITE_MESSAGES.success <Navigate to={"/"} /></>);
                } else {
                    setError(true);
                    openAlert({ content: `${res.errors.message} ${res.errors.status}, ${INVITE_MESSAGES.failed}` });
                }
            })
            .catch((e) => {
                e.response?.status === 422 ?
                    openAlert({ content: e.response.data.errors?.email.join("<br />") })
                    : openAlert({ content: INVITE_MESSAGES.failed_acceptance + e.response?.status });
                setError(true);
            })
            .finally(() => setLoading(false));
        // accept button use api service to post accept
    };

    return (<Register>
        <AlertDialog />
        <Box sx={{ padding: 2 }}>{message}</Box>
        {
            loading ? (<Loading isOpen={true} message={message} />) :
                (<form onSubmit={handleAccept} noValidate>
                    <Box sx={{ padding: 2 }}>
                        <TextField
                            required
                            id="outlined-required"
                            name="email"
                            label="Verify Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ padding: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={invite.action === "expired" || error || !email}
                        >
                            Accept
                        </Button>
                    </Box>
                </form>)
        }
    </Register>);
};
