import * as React from 'react';
import { Component, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { AdminPermissions, Organization } from '../../../types';
import axios from 'axios';
import APIClient from '../../service/api.service';

interface EditOrganizationDialogProps {
    open: boolean,
    closeHandler: Function,
    organization: Organization,
    // onSave: (organization: Organization) => void,
}

export default class EditOrganizationDialog extends Component<EditOrganizationDialogProps> {
    render () {
    const permissionsList = this.props.organization.permissions;
    const setPermissions = (params) => this.setState({permissions: params});

    const permissionChangeHandler = (value) => {        
        setPermissions(value);
    };

    // const onSave = () => {
    //     axios
    //     .post(`${APIClient}/organizations/${this.props.organization.id}`, this.props.organization.permissions)
    //     .then(res => res.data)
    // }

    return (
        <Dialog open={this.props.open} onClose={() => { this.props.closeHandler(false)}}>

            <DialogTitle>
                {'Edit organization permissions'}
            </DialogTitle>

            {/* <DialogContent dividers>
                    {permissionsList.map((arrayItem:AdminPermissions) => (
                    <FormControlLabel control={<Checkbox/>}
                        onChange={permissionChangeHandler}
                        key={arrayItem.id as number}
                        value={arrayItem.permission}label={arrayItem.name} />
                    ))}
            </DialogContent> */}

            <DialogActions>
                <Button
                onClick={() => { this.props.closeHandler(false)}}
                type='submit'>
                    {'Save'}
                </Button>
                <Button onClick={() => { this.props.closeHandler(false)}}>
                    {'Cancel'}
                </Button>
            </DialogActions>

        </Dialog>
    );
                    }
}