import React from "react";
import { Box } from "@mui/material";

export default function Register({children}) {
    return (
        <div>
            <div className="Header">
                <div className="Title">
                    Accept Your Invitation
                </div>
            </div>

            <Box sx={{ width: "100%" }}>
                {children}
            </Box>
        </div>
    );
}
